import React, { Fragment, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import BackspaceIcon from '@mui/icons-material/Backspace';
import SendIcon from '@mui/icons-material/Send';

const NumKey = styled(Button)({
  fontSize: 20,
  padding: 0,
  border: '2px solid',
  minWidth: '100%',
  height: '50px',
  lineHeight: 1.5,
  backgroundColor: 'grey',
  borderColor: 'black',
  fontFamily: 'inherit',
  '&:hover': {
    backgroundColor: 'darkgrey'
  }
});

const BigKey = styled(Button)({
  fontSize: 20,
  padding: 0,
  border: '2px solid',
  width: '100%',
  height: '50px',
  lineHeight: 1.5,
  backgroundColor: 'grey',
  borderColor: 'black',
  fontFamily: 'inherit',
  '&:hover': {
    backgroundColor: 'darkgrey'
  }
});

const Keyboard = (props) => {

  useEffect(() => { 
    window.addEventListener("keydown", onKeyPress);
    return () => {window.removeEventListener("keydown", onKeyPress)}
  }, [props.input]);

  const onKeyPress = (e) => {
    if (props.disable) {
      return;
    }
    props.input(e.key);
  }

  const onButtonPress = (e) => {
    if (props.disable) {
      return;
    }
    props.input(e);
  }
  
  return (
    <Fragment>
      <Grid container spacing={0.5}>
      
        <Grid item xs={1}></Grid>
        <Grid item xs={1}><NumKey variant="contained" onClick={() => onButtonPress('1')} disabled={props.disable} disableRipple>1</NumKey></Grid>
        <Grid item xs={1}><NumKey variant="contained" onClick={() => onButtonPress('2')} disabled={props.disable} disableRipple>2</NumKey></Grid>
        <Grid item xs={1}><NumKey variant="contained" onClick={() => onButtonPress('3')} disabled={props.disable} disableRipple>3</NumKey></Grid>
        <Grid item xs={1}><NumKey variant="contained" onClick={() => onButtonPress('4')} disabled={props.disable} disableRipple>4</NumKey></Grid>
        <Grid item xs={1}><NumKey variant="contained" onClick={() => onButtonPress('5')} disabled={props.disable} disableRipple>5</NumKey></Grid>
        <Grid item xs={1}><NumKey variant="contained" onClick={() => onButtonPress('6')} disabled={props.disable} disableRipple>6</NumKey></Grid>
        <Grid item xs={1}><NumKey variant="contained" onClick={() => onButtonPress('7')} disabled={props.disable} disableRipple>7</NumKey></Grid>
        <Grid item xs={1}><NumKey variant="contained" onClick={() => onButtonPress('8')} disabled={props.disable} disableRipple>8</NumKey></Grid>
        <Grid item xs={1}><NumKey variant="contained" onClick={() => onButtonPress('9')} disabled={props.disable} disableRipple>9</NumKey></Grid>
        <Grid item xs={1}><NumKey variant="contained" onClick={() => onButtonPress('0')} disabled={props.disable} disableRipple>0</NumKey></Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={5}><BigKey variant="contained" onClick={() => onButtonPress('Enter')} disabled={props.disable} disableRipple><SendIcon /></BigKey></Grid>
        <Grid item xs={5}><BigKey variant="contained" onClick={() => onButtonPress('Backspace')} disabled={props.disable} disableRipple><BackspaceIcon /></BigKey></Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Fragment>

  );
};

export default Keyboard;