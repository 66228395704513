import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import classes from './StatsDialog.module.css';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import coffeeImage from '../../assets/ko-fi.png';
import Button from '@mui/material/Button';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function StatsDialog(props) {

  const handleClose = () => {
    props.onClose(true);
  };

  return (
    <Dialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    open={props.statsDialogShown}
    >
        <BootstrapDialogTitle sx={{ m:0 }} id="customized-dialog-title" onClose={handleClose}>
          Statistics
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <table className={classes.itemPaddingBottom}>
            <thead>
              <tr>
                <th>Current Streak</th>
                <th>Highest Streak</th>
                <th>Games Played</th>
                <th>Games Won</th>
                <th>Average Guesses</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.statsData}>{props.currentStreak}</td>
                <td className={classes.statsData}>{props.maxStreak}</td>
                <td className={classes.statsData}>{props.gamesPlayed}</td>
                <td className={classes.statsData}>{props.gamesWon}</td>
                <td className={classes.statsData}>{props.averageGuesses.toFixed(3)}</td>
              </tr>
            </tbody>
          </table>
          <Divider />
          <Typography className={classes.itemPadding}>
          <strong>Hard Mode</strong><Switch className={classes.statsSwitch} checked={props.hardMode} onChange={props.toggleHardMode} disabled={props.gameState !== 'NEW'}/>
          </Typography>
          <Divider />
          <Typography className={classes.itemPadding}>
          <strong>Dark Mode</strong><Switch className={classes.statsSwitch} checked={props.darkMode} onChange={props.toggleDarkMode} />
          </Typography>
          <Divider />
          <Typography className={classes.itemPadding}>
          <strong>Colorblind Mode</strong><Switch className={classes.statsSwitch} checked={props.colorBlindMode} onChange={props.toggleColorBlindMode}/>
          </Typography>
          <Divider />
          <Typography sx={{ textAlign: 'center' }} className={classes.itemPadding}>
            Inspired by <Link target="_blank" href="https://www.nytimes.com/games/wordle/index.html">Wordle</Link>
            <br />
            Facts sourced from <Link target="_blank" href="https://www.wikipedia.org">Wikipedia</Link>
            <br />  
          </Typography>
          <center>
            <Button variant="contained" color="secondary" target="_blank" href="https://rentumbokon.com/support.html">
                <img className={classes.bmcImage} src={coffeeImage} alt="Support me!" />Buy me a Coffee <ArrowForwardIcon className={classes.bmcArrow} />
            </Button>
          </center>
        </DialogContent>
    </Dialog>
  );
}