import React, { useState, useEffect } from 'react';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const Hint = (props) => {
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        if (props.children === null || props.children === '' || props.gameState === 'WIN' || props.gameState === 'LOSE') {
            setDisplay(false);
            return;
        }
        setDisplay(true);
    }, [props.children, props.gameState])

    return (
        <Collapse sx={{ width: '100%' }} in={display}>
            <Alert
                variant="outlined" 
                severity="info"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setDisplay(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
            >
                {props.children}
            </Alert>
        </Collapse>
    );
};

export default Hint;