export const dateToMonthDay = (date) => {
    return date.toLocaleDateString('en-US', {month: "long"}) + " " + date.getDate(); // new Date - > "January 1"
}

export const dateToGameId = (date) => {
    return getLastTwoYearDigits(date) + getTwoDigitMonth(date) + getTwoDigitDay(date);
}

export const startOfNextDay = (date) => {
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1)
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
}

// For browser caching date format
export const dateToLocalDate = (date) => {
    //return date.toISOString().split('T')[0];
    let day = new Date().toLocaleDateString('en-us',{day: 'numeric'});
    let month = new Date().toLocaleDateString('en-us',{month: 'numeric'});
    let year = new Date().toLocaleDateString('en-us',{year: 'numeric'});
    return `${year}-${month}-${day}`
}

function getLastTwoYearDigits(date) {
    return String(date.getFullYear()).substring(2);
}

function getTwoDigitMonth(date) {
    let month = date.getMonth() + 1;
    if (month <= 9) {
        return '0' + month;
    }
    return month;
}

function getTwoDigitDay(date) {
    let day = date.getDate();
    if (day <= 9) {
        return '0' + day;
    }
    return day;
}