import { createTheme } from '@mui/material/styles';

//const DARK_BLUE = "#5769c2";
const LIGHT_BROWN = "#531C00";

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
});

const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
          main: LIGHT_BROWN
        }
    }
});

export { darkTheme, lightTheme }