import React, { useState, useEffect } from 'react';

import Alert from '@mui/material/Alert';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import * as dateUtil from '../../constants/DateUtil';
import Snackbar from '@mui/material/Snackbar';
import Countdown from 'react-countdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const WinAlert = (props) => {
    const [gameState, setGameState] = useState('NEW');
    const [displayCopiedSnackbar, setDisplayCopiedSnackbar] = useState(false);

    useEffect(() => {
        setGameState(props.gameState);
    }, [props.gameState])

    const handleCopiedSnackbarClose = () => {
        setDisplayCopiedSnackbar(false);
    };

    const getEmojiGridShareText = () => {
        const feedbackGrid = props.feedbackGrid;
        let emojiGrid = '';
        for (let i = 0; i < feedbackGrid.length; i++) {
            for (let j = 0; j < feedbackGrid[i].length; j++) {
                if (feedbackGrid[i][j] === 'WRONG') {
                    emojiGrid += '🟥';
                } else if (feedbackGrid[i][j] === 'CORRECT') {
                    emojiGrid += '🟩';
                } else if (feedbackGrid[i][j] === 'ALMOST')  {
                    emojiGrid += '🟦';
                } else if (feedbackGrid[i][j] === 'UNKNOWN') {
                    emojiGrid += '⬛';
                } else {
                    emojiGrid += '⬛';
                }
            }
            emojiGrid += "\n"
        }
        return emojiGrid;
    }

    const getStreakShareText = () => {
        if (props.currentStreak  > 1) {
            return '(' + props.currentStreak + '🔥)' 
        } else {
            return '';
        }
    }

    const generateShareText = () => {
        return 'Daydle#' + dateUtil.dateToGameId(props.date) 
        + '\n' + getScoreShareText() + (props.hardMode ? '*' : '') + ' ' + getStreakShareText()
        + '\n' + getEmojiGridShareText() 
        + window.location.origin + '\n';
    }

    const getScoreShareText = () => {
        const numTries = getNumTries();
        return (numTries === -1 ? 'X' : numTries) + '/' + props.feedbackGrid.length;
    }

    const getTomorrowMidnight = () => {
        const today = new Date()
        const tomorrow = dateUtil.startOfNextDay(today);
        return tomorrow.getTime();
    }

    const getNumTries = () => {
        // only gets numTries when win, -1 otherwise
        for (let i = 0; i < props.feedbackGrid.length; i++) {
            if (props.feedbackGrid[i][0] === 'CORRECT'
                    && props.feedbackGrid[i][1] === 'CORRECT'
                    && props.feedbackGrid[i][2] === 'CORRECT'
                    && props.feedbackGrid[i][3] === 'CORRECT') {
                return (i + 1);
            }
        }
        return -1;
    }

    const getPostGameEmoji = () => {
        const numTries = getNumTries();
        switch(numTries) {
            case 1:
                return '🤓';
            case 2:
                return '🥳';
            case 3:
                return '😁';
            case 4:
                return '😅';
            case 5:
                return '🥲';
            case 6:
                return '🥵';
            default:
                return '😭';
        }
    }

    return (
        <Collapse sx={{ width: '100%' }} in={(gameState !== 'NEW' && gameState !== 'IN_PROGRESS')}>
            <Alert
                variant="outlined" 
                severity={gameState === 'WIN' ? "success" : "error"}
                action={
                    <CopyToClipboard
                        onCopy={() => setDisplayCopiedSnackbar(true)}
                        text={generateShareText()}
                        options={{
                            format: 'text/plain'
                        }}
                    >
                        <IconButton
                            aria-label="share"
                            color="inherit"
                            size="small"
                        >
                            <ShareIcon fontSize="inherit" />
                        </IconButton>
                        
                    </CopyToClipboard>
                }
                sx={{ mb: 2, textAlign: 'left' }}
            >
                This happened on this day in {props.solution}: <strong>{gameState === 'WIN' ? 'Took you '+ getNumTries() +' tries!' : 'You Lose!'}</strong>{' ' + getPostGameEmoji()}
                <br />
                &#8212; Next Daydle in <Countdown daysInHours={true} date={getTomorrowMidnight()} />
            </Alert>
            <Snackbar
                sx={{textAlign: 'center'}}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={displayCopiedSnackbar}
                onClose={handleCopiedSnackbarClose}
                autoHideDuration={1500}
                direction="down"
                message="Copied to clipboard!"
            ></Snackbar>
        </Collapse>
    );
};

export default WinAlert;