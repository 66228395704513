export const getHardMode = (isColorBlindMode) => {
    return {
        correct: isColorBlindMode ? COLORBLIND_MODE.correct : EASY_MODE.correct,
        almost: isColorBlindMode ? COLORBLIND_MODE.wrong : EASY_MODE.wrong,
        wrong: isColorBlindMode ? COLORBLIND_MODE.wrong : EASY_MODE.wrong,
        unknown: isColorBlindMode ? COLORBLIND_MODE.unknown : EASY_MODE.unknown,
        unused: 'primary.contrastText'
    };
};

export const EASY_MODE = {
    correct: '#2e7d32',
    almost: '#03a9f4',
    wrong: '#d32f2f',
    unknown: 'primary.contrastText',
    unused: 'primary.contrastText',
};

export const COLORBLIND_MODE = {
    correct: '#f5793a',
    almost: '#85c0f9',
    wrong: '#f748a5',
    unknown: 'primary.contrastText',
    unused: 'primary.contrastText'
};