import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const QuestionSkeleton = () => {
  return (
    <Stack alignItems="center">
      <Skeleton variant="text" animation="wave" width='100%' />
      <Skeleton variant="text" animation="wave" width='75%' />
    </Stack>
  );
};

export default QuestionSkeleton;