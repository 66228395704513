import './App.css';
import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import HintAlert from './components/Alert/HintAlert';
import GameEndAlert from './components/Alert/GameEndAlert';
import YearGrid from './components/YearGrid/YearGrid';
import Grid from '@mui/material/Grid';
import Keyboard from './components/Keyboard/Keyboard';
import QuestionSkeleton from './components/Skeleton/QuestionSkeleton';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import * as httpUtil from './constants/HttpUtil';
import * as dateUtil from './constants/DateUtil';
import * as colorUtil from './constants/ColorUtil';
import * as parseHtmlUtil from './constants/ParseHtmlUtil';
import { useLocalStorage } from "./constants/LocalStorage";
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './components/Theme/Theme';
import { CssBaseline } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import parse from 'html-react-parser'

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import HelpDialog from './components/Dialog/HelpDialog';
import StatsDialog from './components/Dialog/StatsDialog';

function App() {

  // Game Objects
  const [cookies, setCookies, removeCookie] = useCookies(['lastPlayedDate', 'gameState', 'question', 'hint', 'yearGrid', 'feedbackGrid', 'solutionYear']);
  const [gameState, setGameState] = useState('NEW');
  const [date] = useState(new Date());
  const [question, setQuestion] = useState(null);
  const [hint, setHint] = useState(null);
  const [rowIndex, setRowIndex] = useState(0);
  const [colIndex, setColIndex] = useState(0);
  const [solutionYear, setSolutionYear] = useState(null);
  const [yearGrid, setYearGrid] = useState([
    ['', '', '', ''],
    ['', '', '', ''],
    ['', '', '', ''],
    ['', '', '', ''],
    ['', '', '', ''],
    ['', '', '', '']
  ]);
  const [feedbackGrid, setFeedbackGrid] = useState([
    ['', '', '', ''],
    ['', '', '', ''],
    ['', '', '', ''],
    ['', '', '', ''],
    ['', '', '', ''],
    ['', '', '', '']
  ]);
  const [userInput, setUserInput] = useState(''); 
  const [isLoadingGame, setLoadingGame] = useState(true);
  const [isLoadingHint, setLoadingHint] = useState(false);
  const [helpDialogShown, setHelpDialogShown] = useState(false);
  const [statsDialogShown, setStatsDialogShown] = useState(false);
  const [displayErrorSnackbar, setDisplayErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [feedbackColorPalette, setFeedbackColorPalette] = useState(colorUtil.EASY_MODE);

  // User Statistics
  const [currentStreak, setCurrentStreak] = useLocalStorage('currentStreak', 0);
  const [maxStreak, setMaxStreak] = useLocalStorage('maxStreak', 0);
  const [gamesPlayed, setGamesPlayed] = useLocalStorage('gamesPlayed', 0);
  const [gamesWon, setGamesWon] = useLocalStorage('gamesWon', 0);
  const [averageGuesses, setAverageGuesses] = useLocalStorage('averageGuesses', 0);

  // Settings
  const [darkMode, setDarkMode] = useLocalStorage('darkMode', false);
  const [colorBlindMode, setColorBlindMode] = useLocalStorage('colorBlindMode', false);
  const [hardMode, setHardMode] = useLocalStorage('hardMode', false);
  const [isNewPlayer, setIsNewPlayer] = useLocalStorage('newPlayer', true);

  useEffect(() => { // On app startup
    // New player pop up
    if (isNewPlayer) {
      setHelpDialogShown(true);
      setIsNewPlayer(false);
    }

    // Set grid color palette
    handleSetFeedbackColorPalette();

    // Game load
    if (initGameWithCookies(date)) {
      handleSetLastPlayedDate(date);
      setLoadingGame(false);
    } else {
      const initGameRequest = httpUtil.getGameInitRequest(date);
  
      axios.get(initGameRequest.url, initGameRequest.config)
        .then(response => {
          console.log("Initialized new game: " + response.data.event);
          handleSetQuestion(response.data.event);
          handleSetLastPlayedDate(date);
          setLoadingGame(false);
        })
        .catch((error) => {
          displayErrorMessage(error);
        });
    }
  }, []);

  useEffect(() => {
    handleSetFeedbackColorPalette();
  }, [hardMode, colorBlindMode])

  function initGameWithCookies(date) {
    if (cookies.gameState == null // Note: leave as '==' (not '===') or will evaluate unexpectedly 
      || cookies.question == null
      || (cookies.hint == null && cookies.solutionYear == null)
      || cookies.yearGrid == null 
      || cookies.feedbackGrid == null
      || (cookies.gameState !== 'IN_PROGRESS' && cookies.solutionYear == null)
      || (cookies.lastPlayedDate == null || cookies.lastPlayedDate !== dateUtil.dateToGameId(date))) {
        console.log('Clearing cookies...')
        clearAllCookies();
        return false;
    } else {
      console.log("Loading game from previous save: " + cookies.lastPlayedDate);
      setGameState(cookies.gameState);
      setQuestion(cookies.question);
      setHint(cookies.hint);
      setYearGrid(cookies.yearGrid);
      setFeedbackGrid(cookies.feedbackGrid);
      setSolutionYear(cookies.solutionYear);
      for (let i = 0; i < cookies.feedbackGrid.length; i++) {
        for (let j = 0; j < cookies.feedbackGrid[i].length; j++) {
          if (cookies.feedbackGrid === null || cookies.feedbackGrid[i][j] === '') {
            setRowIndex(i);
            setColIndex(j);
            return true;
          }
        } 
      }
      return true;
    }
  }

  function clearAllCookies() {
    removeCookie('lastPlayedDate');
    removeCookie('gameState');
    removeCookie('question');
    removeCookie('hint');
    removeCookie('yearGrid');
    removeCookie('feedbackGrid');
    removeCookie('solutionYear');       
  }

  function populateFeedbackGrid(feedback) {
    setFeedbackGrid((prevGrid) => {
      const newGrid = prevGrid;
      for (let i = 0; i < feedback.length; i++) {
        newGrid[rowIndex][i] = feedback[i];
      }
      return newGrid;
    });
  }

  function populateYearGrid(year) {
    setYearGrid((prevGrid) => {
      const newGrid = prevGrid;
      for (let i = 0; i < year.length; i++) {
        newGrid[rowIndex][i] = year.charAt(i);
      }
      return newGrid;
    });
  }

  function onKeyPress(key) {
    if (key !== " " && key >= 0 && key <= 9) {
      numKeyPress(key);
    } else if (key === "Backspace") {
      deleteKeyPress();
    } else if (key === "Enter") {
      enterKeyPress();
    }
  }

  function numKeyPress(num) {
    if (rowIndex >= yearGrid.length || userInput.length + colIndex >= yearGrid[0].length) {
      return;
    }
    setUserInput(userInput + num);
  }

  function deleteKeyPress() {
    if (rowIndex >= yearGrid.length || userInput.length === 0) {
      return;
    }
    setUserInput(userInput.substring(0, userInput.length - 1));
  }

  function populateRowWithKnownCols(rowIndex, feedback, yearGuess) {
    for (let i = 0; i < feedback.length; i++) {
      if (feedback[i] !== 'CORRECT') { // set col index
        setColIndex(i);
        return;
      } else { // fill correct cols with digit and color
        setFeedbackGrid((prevGrid) => {
          const newGrid = prevGrid;
          newGrid[rowIndex][i] = feedback[i];
          setCookies('feedbackGrid', newGrid, {expires: dateUtil.startOfNextDay(date)});
          return newGrid;
        });
        setYearGrid((prevGrid) => {
          const newGrid = prevGrid;
          newGrid[rowIndex][i] = yearGuess.charAt(i);
          setCookies('yearGrid', newGrid, {expires: dateUtil.startOfNextDay(date)});
          return newGrid;
        });
      }
    }
  }

  function enterKeyPress() {
    if (colIndex + userInput.length !== 4) {
      displayErrorMessage('Needs 4 digits');
      return;
    }
    let yearGuess = '';
    for (let i = 0; i < feedbackGrid.length; i++) {
      if (feedbackGrid[rowIndex][i] === 'CORRECT') {
        yearGuess = yearGuess + yearGrid[rowIndex][i];
      }
    }
    yearGuess = yearGuess + userInput;
    if (Number(yearGuess) > date.getFullYear()) {
      displayErrorMessage('Are you from the future?');
      return;
    }
    setLoadingHint(true);
    const guessRequest = httpUtil.getGameGuessRequest(date, yearGuess, (rowIndex === yearGrid.length - 1), hardMode);

    axios.get(guessRequest.url, guessRequest.config)
      .then(response => {
        handleSetQuestion(response.data.event);
        handleSetGameState(response.data.status);
        populateFeedbackGrid(response.data.feedback);
        populateYearGrid(yearGuess);

        if (response.data.status === 'WIN') {
          handleSetSolutionYear(response.data.solutionYear);
          setCookies('feedbackGrid', feedbackGrid, {expires: dateUtil.startOfNextDay(date)});
          setCookies('yearGrid', yearGrid, {expires: dateUtil.startOfNextDay(date)});
          saveEndGameStats(response.data.status);
        } else if (response.data.status === 'LOSE') {
          handleSetSolutionYear(response.data.solutionYear);
          setCookies('feedbackGrid', feedbackGrid, {expires: dateUtil.startOfNextDay(date)});
          setCookies('yearGrid', yearGrid, {expires: dateUtil.startOfNextDay(date)});
          saveEndGameStats(response.data.status);
        } else { // 'IN_PROGRESS'
          handleSetHint(response.data.hint);
          setRowIndex(rowIndex + 1);
          populateRowWithKnownCols(rowIndex + 1, response.data.feedback, yearGuess);
        }
        handleSetLastPlayedDate(date);
        setLoadingHint(false);
        setUserInput('');
      })
      .catch((error) => {
        displayErrorMessage(error);
      });
  }

  function saveEndGameStats(gameState) {
    setAverageGuesses((gamesPlayed * averageGuesses + rowIndex) / (gamesPlayed + 1));
    setGamesPlayed(gamesPlayed + 1);
    if (gameState === 'WIN') {
      setMaxStreak(currentStreak + 1 >= maxStreak ? currentStreak + 1 : maxStreak);
      setCurrentStreak(currentStreak + 1);
      
      setGamesWon(gamesWon + 1);
    } else if (gameState === 'LOSE') {
      setCurrentStreak(0);
    }
  }

  function displayErrorMessage(message) {
    console.log("API Error: " + message);
    setErrorMessage(message);
    setDisplayErrorSnackbar(true);
  }

  const showHelpDialog = () => {
    setHelpDialogShown(true);
  }

  const closeHelpDialog = () => {
    setHelpDialogShown(false);
  }

  const showStatsDialog = () => {
    setStatsDialogShown(true);
  }

  const closeStatsDialog = () => {
    setStatsDialogShown(false);
  }

  const closeErrorSnackbar = () => {
    setDisplayErrorSnackbar(false);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  }

  const toggleColorBlindMode = () => {
    setColorBlindMode(!colorBlindMode);
  }

  const toggleHardMode = () => {
    setHardMode(!hardMode);
  }

  function handleSetGameState(gameState) {
    setCookies('gameState', gameState, {expires: dateUtil.startOfNextDay(date)});
    setGameState(gameState);
  }

  function handleSetQuestion(question) {
    let questionToSet = question;
    setCookies('question', questionToSet, {expires: dateUtil.startOfNextDay(date)});
    setQuestion(questionToSet);
  }

  function handleSetHint(hint) {
    setCookies('hint', hint, {expires: dateUtil.startOfNextDay(date)});
    setHint(hint);
  }

  function handleSetSolutionYear(solutionYear) {
    setCookies('solutionYear', solutionYear, {expires: dateUtil.startOfNextDay(date)});
    setSolutionYear(solutionYear);   
  }

  function handleSetLastPlayedDate(lastPlayedDate) {
    let localDate = dateUtil.dateToGameId(lastPlayedDate);
    setCookies('lastPlayedDate', localDate, {expires: dateUtil.startOfNextDay(date)});
  }

  function handleSetFeedbackColorPalette() {
    if (hardMode) {
      setFeedbackColorPalette(colorUtil.getHardMode(colorBlindMode));
    } else if (colorBlindMode) {
      setFeedbackColorPalette(colorUtil.COLORBLIND_MODE);
    } else {
      setFeedbackColorPalette(colorUtil.EASY_MODE);
    }
  }

  return (
    <div className="App">
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <Header showHelpDialog={showHelpDialog} showStatsDialog={showStatsDialog} />
        <HelpDialog 
          helpDialogShown={helpDialogShown} 
          feedbackColorPalette={feedbackColorPalette}  
          onClose={closeHelpDialog} />
        <StatsDialog 
        statsDialogShown={statsDialogShown} 
        onClose={closeStatsDialog} 
          currentStreak={currentStreak}
          maxStreak={maxStreak}
          gamesPlayed={gamesPlayed}
          gamesWon={gamesWon}
          averageGuesses={averageGuesses}
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
          toggleColorBlindMode={toggleColorBlindMode}
          colorBlindMode={colorBlindMode}
          toggleHardMode={toggleHardMode}
          hardMode={hardMode}
          gameState={gameState} />
        <Snackbar
          sx={{textAlign: 'center', paddingTop: 6, left: 0, right: 0}}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={displayErrorSnackbar}
          onClose={closeErrorSnackbar}
          autoHideDuration={1500}
          direction="down">
          <Alert onClose={closeErrorSnackbar} severity="error" sx={{ width: '100%' }}>{errorMessage}</Alert>
        </Snackbar>
          <main>
            <Container maxWidth="sm">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {(hardMode ? '*' : '') + dateUtil.dateToMonthDay(date) + (hardMode ? '*' : '')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body">
                    {question ? parse(question, parseHtmlUtil.parseLinkOptions) : <QuestionSkeleton />}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <HintAlert gameState={gameState}>{hint}</HintAlert>
                  <GameEndAlert 
                    gameState={gameState} 
                    feedbackGrid={feedbackGrid} 
                    solution={solutionYear} 
                    numTries={rowIndex + 1} 
                    date={date} 
                    hardMode={hardMode}
                    currentStreak={currentStreak} />
                </Grid>
                <Grid item xs={12}>
                  <YearGrid 
                    yearGrid={yearGrid} 
                    feedbackGrid={feedbackGrid} 
                    feedbackColorPalette={feedbackColorPalette} 
                    userInput={userInput} 
                    rowIndex={rowIndex} 
                    colIndex={colIndex} 
                    gameState={gameState} 
                    loading={isLoadingHint} />
                </Grid>
                <Grid item xs={12}>
                  <Keyboard 
                  input={onKeyPress} 
                  disable={isLoadingGame || isLoadingHint || (gameState !== 'IN_PROGRESS' && gameState !== 'NEW')} />
                </Grid>
              </Grid>
              <Typography sx={{ fontSize: '12px', paddingTop: '1em', paddingBottom: '1em' }} gutterBottom>by <Link color="inherit" target="_blank" href="https://rentumbokon.com">renard</Link></Typography>
            </Container>
            
          </main>
      </ThemeProvider>
    </div>
  );
}

export default App;
