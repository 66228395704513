import Link from '@mui/material/Link';
import { domToReact } from 'html-react-parser';

export const parseLinkOptions = {
    replace: ({ attribs, name, children }) => {
        attribs = attribs || {};
        if (name === 'a') {
          return <Link {...attribs} >{domToReact(children, parseLinkOptions)}</Link>;
        }
      }   
}