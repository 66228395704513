import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import classes from './HelpDialog.module.css'
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function HelpDialog(props) {

  const handleClose = () => {
    props.onClose(true);
  };

  return (
    <Dialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    open={props.helpDialogShown}
    >
        <BootstrapDialogTitle sx={{ m:0 }} id="customized-dialog-title" onClose={handleClose}>
          How to Play
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <Typography className={classes.itemPaddingBottom}>
          Guess the year this <strong>DAYDLE</strong> happened on this day in history.
        </Typography>
        <Divider />
        <Typography className={classes.itemPadding}>
          You have 6 tries to guess the 4 digit year, and after each incorrect guess:
        </Typography>
        <table className={classes.itemPadding}>
          <tbody>
            <tr>
              <td><InfoOutlinedIcon />&nbsp;</td>
              <td>A historical hint will describe if the Daydle happened chronologically before or after your guess.</td>
            </tr>
            <tr>
              <td className={classes.itemPaddingTop}><ColorLensOutlinedIcon />&nbsp;</td>
              <td className={classes.itemPaddingTop}>The digits of your guess will change color depending on if they are...</td>
            </tr>
            <tr>
                <td colSpan="2">
                  <table className={classes.indentLeft}>
                    <tbody>
                      <tr>
                        <td><Typography className={classes.box} sx={{ bgcolor: props.feedbackColorPalette.correct, color: 'text.primary' }} >1</Typography></td>
                        <td>Correct:</td>
                        <td>Get all 4 digits to win</td>
                      </tr>
                      <tr>
                        <td><Typography className={classes.box} sx={{ bgcolor: props.feedbackColorPalette.almost, color: 'text.primary' }} >2</Typography></td>
                        <td>Almost:</td>
                        <td>Incorrect, but ±1 away from being correct <br/> (disabled on hard mode)</td>
                      </tr>
                      <tr>
                        <td><Typography className={classes.box} sx={{ bgcolor: props.feedbackColorPalette.wrong, color: 'text.primary' }} >3</Typography></td>
                        <td>Incorrect:</td>
                        <td>Stops evaluating subsequent digits</td>
                      </tr>
                      <tr>
                        <td><Typography className={classes.box} sx={{ bgcolor: props.feedbackColorPalette.unknown, color: 'text.primary' }} >4</Typography></td>
                        <td>Unknown:</td>
                        <td>Not evaluated</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
            </tr>
          </tbody>
        </table>
        <center>
        <Typography className={classes.itemPadding}>
          Challenge your friends by sharing your results.
        </Typography>
        <Divider />
          <Typography className={classes.itemPadding}>
                <strong>Each day is a new DAYDLE!</strong>
          </Typography>
          <Button className={classes.itemPaddingTop} sx={{ fontWeight: 'bold' }} variant="contained" color="success" onClick={handleClose}>Play</Button>
        </center>
        </DialogContent>
    </Dialog>
  );
}