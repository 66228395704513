import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';

import classes from './YearGrid.module.css';

const Cell = styled(Paper)({
  fontSize: 32,
  border: '2px solid',
  width: '50px',
  height: '50px',
  lineHeight: 1.5,
  backgroundColor: 'white',
  borderColor: 'black',
  fontFamily: 'inherit',
});

// https://mui.com/customization/default-theme/
const UNKNOWN_COLOR = 'primary.contrastText';
const FONT_COLOR = 'text.primary';
const SHADOW = 10;



const YearGrid = (props) => {

  function getBackgroundColor(feedback) {
    switch(feedback) {
      case 'CORRECT':
        return props.feedbackColorPalette.correct;
      case 'WRONG':
        return props.feedbackColorPalette.wrong;
      case 'ALMOST':
        return props.feedbackColorPalette.almost;
      case 'UNKNOWN':
        return props.feedbackColorPalette.unknown;
      default:
        return props.feedbackColorPalette.unused;
    }
  }

  function generateRow(rowNum) {
    let row = [];
    for (let j = 0; j < props.yearGrid[rowNum].length; j++) {
      if (props.rowIndex === rowNum && props.feedbackGrid[rowNum][j] !== 'CORRECT' && (props.gameState === 'IN_PROGRESS' || props.gameState === 'NEW')) { 
        // cells that require user input
        row.push(
          <Zoom key={rowNum+'-'+j} in={!props.loading}> 
            <Cell className={classes.cellBlink} sx={{ 
              bgcolor: () => UNKNOWN_COLOR, 
              color: FONT_COLOR
            }} elevation={SHADOW}>
                {props.userInput.charAt(j - props.colIndex)}
            </Cell>
          </Zoom>
        );
      } else { 
        // cells not changing
        row.push(
          <Cell key={rowNum+'-'+j} sx={{ 
            bgcolor: () => getBackgroundColor(props.feedbackGrid[rowNum][j]), 
            color: FONT_COLOR
          }} elevation={SHADOW}>
              {props.yearGrid[rowNum][j]}
          </Cell>
        );
      }
    }
    return row;
  }

  let grid = [];
  for (let i = 0; i < props.yearGrid.length; i++) {
    grid.push(
      <Stack key={'stack'+i} direction="row" spacing={0.5}>
        {generateRow(i)}
      </Stack>
    );
  }

  return (
    <Container maxWidth="sm">
      <Stack className={classes.gridStack} direction="column" spacing={0.5}>
        {grid}
      </Stack>
    </Container>
  );
};

export default YearGrid;