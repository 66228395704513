import sha256 from 'crypto-js/sha256';
const SERVICE_BASE_URL = '' // using proxy in package.json;
const SERVICE_INIT_URI = '/game/{date-seed}';
const SERVICE_GUESS_URI = '/game/{date-seed}/guess/{year-guess}';

const dateToLocalDate = (date) => { // new Date() -> "YYYY-mm-dd"
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};

export const getGameInitRequest = (date) => {
    const localDate = dateToLocalDate(date);
    const url = SERVICE_BASE_URL + SERVICE_INIT_URI.replace('{date-seed}', localDate);

    const httpConfig = getHttpConfig(localDate, localDate);

    return {
        url: url,
        config: httpConfig
    };
};

export const getGameGuessRequest = (date, yearGuess, isFinalGuess, isHardMode) => {
    const localDate = dateToLocalDate(date);
    let url = (SERVICE_BASE_URL + SERVICE_GUESS_URI)
        .replace('{date-seed}', localDate)
        .replace('{year-guess}', yearGuess);

    if (isFinalGuess) {
        url = withRequestParam(url, "final", true);
    }
    if (isHardMode) {
        url = withRequestParam(url, "hardMode", true);
    }

    const httpConfig = getHttpConfig(localDate, yearGuess);

    return {
        url: url,
        config: httpConfig
    };
};

export const dateToMonthDay = (date) => {
    return date.toLocaleDateString('en-US', {month: "long"}) + " " + date.getDate(); // new Date - > "January 1"
}

export const getHttpConfig = (auth1, auth2) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': getAuth(auth1, auth2)
        }
    }
}

const withRequestParam = (url, key, value) => {
    if (url.includes("?")) {
        return url + "&" + key + "=" + value;
    } else {
        return url + "?" + key + "=" + value;
    }
}

const SALT = 'daydle'; // Get salt from backend application.properties
const getAuth = (auth1, auth2) => {
    return sha256(String(SALT) + String(auth1) + String(auth2));
}